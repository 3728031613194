import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youdocs.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/docks_1.svg"
import HeaderImg from "../../assets/img/youDocs/Img1.png"
import Icon1 from "../../assets/img/youDocs/Icon1.png"
import Icon2 from "../../assets/img/youDocs/Icon2.png"
import Icon3 from "../../assets/img/youDocs/Icon3.png"
import Icon4 from "../../assets/img/youDocs/Icon4.png"
import Icon5 from "../../assets/img/youDocs/Icon5.png"
import Icon6 from "../../assets/img/youDocs/Icon6.png"
import Icon7 from "../../assets/img/youDocs/Icon7.png"
import Icon8 from "../../assets/img/youDocs/Icon8.png"
import Icon9 from "../../assets/img/youDocs/Icon9.png"
import Icon10 from "../../assets/img/youDocs/Icon10.png"
import Icon11 from "../../assets/img/youDocs/Icon11.png"
import GroupImg1 from "../../assets/img/youDocs/Img2.png"
import GroupImg2 from "../../assets/img/youDocs/Img3.png"
import TickIcon from "../../assets/img/youDocs/tick.svg"
import SingleForm from "../../components/singleForm"

const YouMAPage = () => {
  return (
    <div>
      <SEO title="Youdocs" keywords={[`youpal`, `youpal group`, `youDocs`]} />

      <div className="youDocsMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                  Knowledge is power, Youdocs keeps it that way
                </h2>
              </div>
            </div>
            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
              Youdocs is a knowledge base for documents, manual and processes. Youdocs helps you build an information and collaboration-driven organisation.{" "}
              </p>
            </div>
            <div className="col-12 order-4 order-md-3">
              <Link to="/products/request-demo">
                <button
                  type="button"
                  className="btn cta btn-primary px-5 mb-md-0 mb-4 mt-4 mt-md-5 productButton d-block mx-auto"
                >
                  <span>Free demo</span>
                </button>
              </Link>
            </div>
            <div className="col-12 order-2 order-md-4">
              <img className="headerImg" src={HeaderImg} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
              Our approach to your challenges{" "}
            </h3>
            <p className="internalNarrowCol">
            Youdocs supports successful communication and seamless knowledge transfer, helping you be more productive.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Knowledge Base</h4>
              <p>
              Youdocs allows you to build a well-connected organisation. Everyone involved has access to find answers.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Easy and Intuitive Collaboration</h4>
              <p>
              Youdocs makes it easy for companies to categorise  content from different departments.{" "}
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Enterprise Wiki</h4>
              <p>
              Youdocs uses the Wiki system and documentation management, so all your content is stored in an easy-to-find format.{" "}
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left color-purple">
                  ANALYTICS
                </div>
                <h3 className="text-left">
                Youdocs helps you optimise project turnaround.{" "}
                </h3>
                <div className="row">
                  <div className="col">
                    <div className="squareHolder">
                      <img src={Icon4} />
                    </div>
                    <h4>Search Engine Optimisation</h4>
                    <p>
                    Youdocs increases search ranks to improve the site’s visibility.
                    </p>
                  </div>
                  <div className="col">
                    <div className="squareHolder">
                      <img src={Icon5} />
                    </div>
                    <h4>Compatibility </h4>
                    <p>
                    Front-end design works elegantly with commonly used web browsers’ latest versions.
                    </p>
                  </div>
                </div>
                <div className="buttonArea">
                  <Link to="/products/request-demo">
                    <button type="button" className="btn btn-primary px-4">
                      <span>Try it out free</span>
                      <FaArrowRight style={{ fill: "white" }} />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader color-purple">
                FEATURES
              </div>
              <h3>Youdoc’s outstanding features</h3>
              {/* <p>
                Our goal is to bring ease to you when writing and creating
                documentation for your product, website, departmental processes,
                online manuals, or your enterprise as a whole.
              </p> */}
              <div className="row mt-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Tiered Roles and Permissions</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Content Categorisation</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Enterprise security </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">


                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Powerful search</h4>
                    </div>
                  </div>
                </div>

                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon6} />
                    </div>
                    <div className="col sideContent">
                      <h4>Mobile Responsive</h4>
                    </div>
                  </div>
                </div>
                
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Media Library</h4>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader color-purple">
                  Benefits
                </div>
                <h3>
                </h3>
                {/* <p className="mt-5">
                  Regardless of the industry, there are times when we all have
                  to do some paperwork, and those days can be marked as black,
                  yet we understood how to take off the drudge from your
                  shoulders, via creating a document agnostic tool.
                </p> */}
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Interconnected digital ecosystem
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Custom-fit
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Institutional memory
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Mobile responsive
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouMAPage
